import React from 'react'

const versionsList = () => {
    const handleRedirect = (e) => {
        window.location = e.target.value
    }

    return (
        <select
            name='forma'
            id='redirectSelect'
            onChange={(e) => handleRedirect(e)}
            defaultValue={'default'}>
            <option value='default' disabled>
                Select
            </option>
            <option value='/third-party-software/5.2'>Lenses 5.2</option>
            <option value='/third-party-software/5.1'>Lenses 5.1</option>
            <option value='/third-party-software/5.0'>Lenses 5.0</option>
            <option value='/third-party-software/4.3'>Lenses 4.3</option>
            <option value='/third-party-software/4.2'>Lenses 4.2</option>
            <option value='/third-party-software/4.1'>Lenses 4.1</option>
            <option value='/third-party-software/4.0'>Lenses 4.0</option>
            <option value='/third-party-software/3.2'>Lenses 3.2</option>
            <option value='/third-party-software/2.3'>Lenses 2.3</option>
            <option value='/third-party-software/2.3'>Lenses 2.2</option>
            <option value='/third-party-software/2.3'>Lenses 2.1</option>
            <option value='/third-party-software/2.3'>Lenses 2.0</option>
            <option value='/third-party-software/landoop-csd'>Landoop CSD</option>
        </select>
    )
}

export default versionsList
